import React, { useEffect, useState } from "react";
import ColorBlockWithText from "./ColorBlockWithText";
import './ColorBlockWithText.css'

const Legend = () => {
    const [isopen, setIsOpen] = useState(true);

    useEffect(() => {
        if (window.innerWidth <= 480) {
            setIsOpen(false);
        }
    }, []);

    const togglePanel = () => {
        setIsOpen(!isopen);
    };
    return (
        <div className="Legendpanel">
            <button onClick={togglePanel} id="legendButton">
                <i className="fa fa-th-list"></i>
            </button>
            {isopen && (
                <div id="legend">
                    <h6>সংকেত</h6>
                    <p>পরিকল্পিত ভূমি ব্যবহার</p>
                    <ColorBlockWithText color="#FFBC00" text="সংরক্ষিত এলাকা" />
                    <ColorBlockWithText color="#00C5FF" text="বিনোদন ও খেলার জায়গা" />
                    <ColorBlockWithText color="#D7D7D7" text="শিল্প" />
                    <ColorBlockWithText color="#38A800" text="শিক্ষা" />
                    <ColorBlockWithText color="#FF0808" text="বাণিজ্যিক" />
                    <ColorBlockWithText color="#FF73DF" text="সড়ক ও যোগাযোগ" />
                    <ColorBlockWithText color="#FFFF73" text="আবাসিক" />
                    <ColorBlockWithText color="#72FF2C" text="কৃষি" />
                    <ColorBlockWithText color="#93D5F2" text="জলাশয়" />
                    <ColorBlockWithText color="#FFBEBE" text="মিশ্র" />
                    <ColorBlockWithText color="#A8A800" text="সেবা" />
                    <ColorBlockWithText color="#A900E6" text="সরকারি জমি" />
                    <p>মৌজা</p>
                    <ColorBlockWithText borderColor="#333333" borderSize="3" />
                    <p>প্রস্তাবিত রাস্তা</p>
                    <ColorBlockWithText color="#FF7F00" text="রাস্তা" />
                    <ColorBlockWithText color="#F35A33" text="রাস্তা কাম গাইড ওয়াল" />
                    <ColorBlockWithText color="#55FF00" text="প্রশস্ত রাস্তা" />
                    <p>বর্তমান রাস্তা</p>
                    <ColorBlockWithText color="#686868" />
                    <p>পরিকল্পিত সেবাসমুহ</p>
                    <ColorBlockWithText borderColor="#42AC0D" borderSize="4" />
                </div>
            )}
        </div>
    );
};

export default Legend;
