import React from 'react';
import { Button } from 'react-bootstrap';

const CustomOverlay = ({ overlayCoordinates, plotInfo, onClose }) => {
    // Calculate the CSS position based on the map coordinates
    const overlayStyle = {
        position: 'absolute',
        bottom: '35px',
        right: '8px',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        minWidth: '350px',  // Set a fixed width
        zIndex: '99',
    };

    // Define the selected properties and their display names
    const selectedProperties = {
        m_thana: 'থানা',
        mouza: 'মৌজা',
        sheet_no: 'শিট নং',
        mz_ver: 'দাগের ধরন',
        plot_no: 'দাগ নং',
        landuse: 'ভুমি ব্যবহার'
    };

    const categoryMapping = {
        'Restricted Area': 'সংরক্ষিত এলাকা',
        'Recreation and Open Space': 'বিনোদন ও খেলার জায়গা',
        'Industry': 'শিল্প',
        'Education and Research': 'শিক্ষা',
        'Commercial': 'বাণিজ্যিক',
        'Transport and Communication': 'সড়ক ও যোগাযোগ',
        'Residential': 'আবাসিক',
        'Agriculture': 'কৃষি',
        'Waterbody': 'জলাশয়',
        'Mixed Use': 'মিশ্র',
        'Service Activity': 'সেবা',
        'Government Service': 'সরকারি জমি'
        // Add mappings for other categories here
    };

    function translateCategories(inputString) {
        const categories = inputString.split(', ');
        const translatedCategories = categories.map(category => {
            return categoryMapping[category] || category;
        });
        return translatedCategories.join(', ');
    }

    const renderProperty = (property) => {
        if (property === 'landuse') {
            if (!plotInfo.landuse || plotInfo.landuse === 'Not Found') {
                return 'উল্লেখ নাই';
            }
            
            // Translate landuse property using translateCategories()
            const translatedLandUse = translateCategories(plotInfo.landuse);
            return translatedLandUse ? translatedLandUse : 'উল্লেখ নাই';
        }
        
        return plotInfo[property] ? plotInfo[property] : 'উল্লেখ নাই';
    };

    return (
        <div style={overlayStyle} className="custom-overlay">
            <Button variant="light" className="close-button" onClick={onClose}>
                &times;
            </Button>
            <h5 className='text-center'><b>ক্লিককৃত দাগ নং এর তথ্য</b></h5>
            <table className="table table-striped">
                <tbody>
                    {Object.keys(selectedProperties).map((property) => (
                        <tr key={property}>
                            <td style={{ maxWidth: '150px', wordWrap: 'break-word' }}>{selectedProperties[property]}:</td>
                            <td style={{ maxWidth: '250px', wordWrap: 'break-word' }}>
                            {renderProperty(property)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CustomOverlay;
