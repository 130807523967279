import './header.css';

export default function Header() {
    return (
        <header className="text-white py-1 header">
            <div className="container d-flex  align-items-center">
                <div className="col-2 headerlogo">
                    <img src="./logo.png" alt="Logo" className="img-fluid" />
                </div>
                <div className="col-6">
                    <h3 className="mb-0">খুলনা উন্নয়ন কর্তৃপক্ষ</h3>
                    <p>গৃহায়ন ও গণপূর্ত মন্ত্রণালয়</p>
                </div>
                <div className="col-4 text-right header_right">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScAvQd0cJV6NiLtAZJUSwRi7db__wCBDZ40wBtgAbIYkmGcXg/viewform?usp=send_form"
                        className="text-white d-block" target='_blank'>সফটওয়্যার সম্পর্কে আপনার মতামত দিন</a>
                </div>
            </div>
        </header>
    );
}
