import { AppContext } from './shared/Context';
import { useState } from 'react';
import './App.css';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import MapSection from './component/map/index';
import LegendPanel from './component/legendBlock/LegendPanel';
import Sidebar from './component/sidebar/Sidebar';
import { usePDF } from "react-to-pdf";

function App() {
  const [extent, setExtent] = useState(null);
  const [mouzaName, setMouzaName] = useState(null);
  const [thanaName, setThanaName] = useState(null);
  const [plotNo, setPlotNo] = useState(null);
  const [plotCenter, setPlotCenter] = useState(null);

  const data = { extent, setExtent, mouzaName, setMouzaName, thanaName, setThanaName, plotNo, setPlotNo, plotCenter, setPlotCenter }
  const { toPDF, targetRef } = usePDF({
    filename: "kda_landuse_info.pdf",
    page: { orientation: "landscape" }
  });

  return (
    <AppContext.Provider value={data}>
      <div className="App" ref={targetRef} style={{ width: "100%", height:'100vh', color: "black" }}>
        <Header />
        <MapSection />
        <LegendPanel />
        <Sidebar toPDF={toPDF} />
        <Footer />
      </div>
    </AppContext.Provider>
  );
}

export default App;