import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from 'ol/layer/Group';
import OSM from 'ol/source/OSM';
import 'ol/ol.css';
import './map.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import LayerSwitcher from 'ol-layerswitcher';
import SatelliteMap from 'ol/source/XYZ';
import XYZ from 'ol/source/XYZ';
import { GetContext } from '../../shared/Context';
import CustomOverlay from './CustomOverlay';


const createTileLayer = (title, name, description, opacity, visible, layers) => {
    return new TileLayer({
        title,
        name,
        description,
        source: new TileWMS({
            params: { LAYERS: layers, TILED: true },
            url: 'http://114.130.119.173:8080/geoserver/k_webapp/wms',
            serverType: 'geoserver',
            crossOrigin: 'anonymous',
            ratio: 1,
        }),
        opacity,
        visible,
    });
};

const Mouza = createTileLayer('মৌজা', 'mouza', 'mouza', 0.7, false, 'mouza');
const Plot = createTileLayer(null, null, null, 1, false, 'mouza_for_info');
const Landuse = createTileLayer('পরিকল্পিত ভূমি ব্যবহার', 'proposed_landuse', 'proposed_landuse', 0.8, true, 'proposed_landuse');
const ProposedRoadLayer = createTileLayer('প্রস্তাবিত রাস্তা', 'proposed_road', 'proposed_road', 0.6, false, 'proposed_road');
const Services = createTileLayer('পরিকল্পিত সেবাসমুহ', 'proposed_services', 'proposed_services', 0.6, false, 'proposed_services');
const ExistingRoadLayer = createTileLayer('বর্তমান রাস্তা', 'existing_roads', 'existing_roads', 0.6, false, 'existing_roads');


const GoogleMap = new TileLayer({
    title: 'গুগোল সাধারণ',
    type: 'base',
    source: new XYZ({
        url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
        //  url: 'https://khms1.googleapis.com/kh?v=152&hl=es-ES&x={x}&y={y}&z={z}'
    }),
    visible: false,
});
const SatelliteMapp = new TileLayer({
    title: 'গুগোল স্যাটালাইট',
    type: 'base',

    source: new SatelliteMap({
        // attributions: ['Powered by Esri'],
        // attributionsCollapsible: false,
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        maxZoom: 23,
    }),
    visible: false,

});
const OsmBase = new TileLayer({
    source: new OSM(),
    title: 'ওএসএম',
    type: 'base',
    visible: false,
});

const KDALayer = [Landuse, Mouza, ProposedRoadLayer, ExistingRoadLayer, Services]

/***********Layer Group ************/
const KDALayerGroup = new LayerGroup({
    fold: 'close',
    name: 'Map',
    layers: KDALayer,
});


const OpenLayersMap = () => {
    const mapRef = useRef();
    const [map, setMap] = useState();
    const [plotInfo, setPlotInfo] = useState(null);
    const [overlayCoordinates, setOverlayCoordinates] = useState(null);
    const [mapExtent, setMapExtent] = useState();
    const [mouzaName, setMouzaName] = useState(null);


    const myContext = GetContext();

    useEffect(() => {
        if (myContext !== null) {
            setMapExtent(myContext.extent);
            setMouzaName(myContext.mouzaName)
        }
    }, [myContext.extent, myContext.mouzaName])

    useEffect(() => {
        const map = new Map({
            layers: [
                new LayerGroup({
                    title: 'বেস ম্যাপ',
                    name: 'Base',
                    fold: 'close',
                    layers: [SatelliteMapp, GoogleMap, OsmBase],
                }),
                new LayerGroup({
                    fold: 'close',
                    title: 'ম্যাপ',
                    layers: KDALayer,
                }),
                Plot
            ],
            target: mapRef.current,
            view: new View({
                //    center: [89.53714770430558, 22.854432879729718],
                zoom: 11,
                projection: 'EPSG:4326',
            }),
        });

        setMap(map);
    }, []);

    const showMap = () => {
        if (map != null || map != undefined) {
            const mapElement = document.getElementById('map');
            if (mapElement.childElementCount) {
                while (mapElement.childElementCount > 1) {
                    mapElement.removeChild(mapElement.firstElementChild);
                }
            }
        }
    }



    useEffect(() => {
        if (map != null || map != undefined) {
            if (myContext.plotCenter == null) {
                const extent = [89.36831031068733, 22.74636262262101, 89.64101974150574, 23.06704156327007];
                map.getView().fit(mapExtent !== null ? mapExtent : extent, { constrainResolution: false });
            } else {
                map.getView().setCenter(myContext.plotCenter);
                map.getView().setZoom(20);
            }
        }
    }, [map, mouzaName, mapExtent, myContext.plotCenter]);



    useEffect(() => {

        if (mouzaName) {
            const mouzaParams = { CQL_FILTER: `mouza = '${mouzaName}'` }
            Mouza.getSource().updateParams(mouzaParams);
            Mouza.setVisible(true);
            Plot.setVisible(false)
            ProposedRoadLayer.setVisible(false);
            Services.setVisible(false);
            ExistingRoadLayer.setVisible(false);
        }
        if (myContext.plotNo) {
            const plotParms = { CQL_FILTER: `mouza = '${mouzaName}' AND plot_no = '${myContext.plotNo}' ` }
            Plot.getSource().updateParams(plotParms);
            Plot.setVisible(true);
            ProposedRoadLayer.setVisible(true);
            Services.setVisible(true);
            ExistingRoadLayer.setVisible(true);
        } else {
            Plot.setVisible(false)
            ProposedRoadLayer.setVisible(false);
            Services.setVisible(false);
            ExistingRoadLayer.setVisible(false);
        };
    }, [mouzaName, myContext.plotNo])

    const handleMapClick = (event) => {
        const viewResolution = map.getView().getResolution();
        const coordinates = event.coordinate;

        const plotInfoUrl = Mouza.getSource().getFeatureInfoUrl(
            coordinates,
            viewResolution,
            'EPSG:4326',
            { INFO_FORMAT: 'application/json' }
        );

        if (plotInfoUrl) {
            fetch(plotInfoUrl)
                .then((response) => response.json())
                .then((html) => {
                    if (html.features.length > 0) {
                        setPlotInfo(html.features[0].properties);
                        setOverlayCoordinates(coordinates);
                    }
                });
        }
    };

    useEffect(() => {
        showMap();
        /* *********** Layer Switcher ********** */
        const layerSwitcher = new LayerSwitcher({
            className: 'custom-layer-switcher'
        });
        if (map != null || map != undefined) {
            map.addControl(layerSwitcher);
        }
        if (map != null) {
            map.on('singleclick', handleMapClick);
        }

    }, [map, mapExtent, mouzaName]);
    const handleCloseOverlay = () => {
        setPlotInfo(null);
        setOverlayCoordinates(null);
    };
    const newMap = () => {
        const FormatExtent = (value) => {
            const format = value.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(function (v) { return v !== '' }).join(',').split(',');
            return format;
        }
        fetch(`http://114.130.119.173:4040/api/select/firstextent`)
            .then(response => response.json())
            .then(data => {
                map.getView().fit(FormatExtent(data?.bbox), { constrainResolution: false });

                if (data) {
                    Mouza.setVisible(false);
                    Plot.setVisible(false)
                    ProposedRoadLayer.setVisible(false);
                    Services.setVisible(false);
                    ExistingRoadLayer.setVisible(false);
                }
            });
            window.location.reload(false);
    }

    return (
        <div className='h-100vh'>
            <div ref={mapRef} className="map" id="map" style={{ width: '100%' }} />
            {plotInfo && overlayCoordinates && <CustomOverlay coordinates={overlayCoordinates} plotInfo={plotInfo} onClose={handleCloseOverlay} />}
            <button onClick={newMap} type="button" className="new_map btn toggle-button pt-10" style={{ right: '10px' }} >
                পূর্বের অবস্থায় ফিরতে ক্লিক করুন
            </button>
        </div>
    );
};

export default OpenLayersMap;
