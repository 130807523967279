import MainMap from "./MainMap";
import Sidebar from "../sidebar/Sidebar";

export default function index() {
    return (
        <>
            <MainMap />
            {/* <Sidebar /> */}
        </>
    )
}
