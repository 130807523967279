import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { GetContext } from '../../shared/Context';
import './sidebar.css';

const Sidebar = ({ toPDF }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [thanas, setThanas] = useState([]);
    const [selectedThana, setSelectedThana] = useState('');
    const [mouzas, setMouzas] = useState([]);
    const [selectedMouza, setSelectedMouza] = useState('');
    const [plots, setPlots] = useState([]);
    const [selectedPlot, setSelectedPlot] = useState('');
    const [plotInfo, setPlotInfo] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [plotSearchQuery, setPlotSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const myContext = GetContext();

    useEffect(() => {
        // Fetch Thanas data on component mount
        fetchThanasData();
        if (window.innerWidth <= 480) {
            setIsOpen(false);
        }
    }, []);


    const fetchThanasData = () => {
        fetch('http://114.130.119.173:4040/api/select/thana')
            .then(response => response.json())
            .then(data => {
                if (data[0].thanas) {
                    setThanas(data[0].thanas);
                    myContext.setExtent(FormatExtent(data[1].bbox));
                } else {
                    setMouzas([]);
                    setSelectedMouza('');
                    setPlots([]);
                    setSelectedPlot([]);
                    setPlotInfo([]);
                }
            });
    };

    const FormatExtent = (value) => {
        const format = value.match(/\(([^)]+)\)/)[1].split(/[ ,]+/).filter(v => v !== '').join(',').split(',');
        return format;
    };

    const handleSelectThana = (event) => {
        const value = event.target.value;
        setSelectedThana(value);
        fetchMouzasData(value);
    };

    const fetchMouzasData = (thanaValue) => {
        fetch(`http://114.130.119.173:4040/api/select/mouza?thana=${thanaValue}`)
            .then(response => response.json())
            .then(data => {
                if (data[0].mouzas) {
                    setMouzas(data[0].mouzas);
                    myContext.setExtent(FormatExtent(data[1].bbox));
                    setPlots([]);
                    setSelectedPlot([]);
                    setPlotInfo([]);
                }
            });
    };

    const handleSelectMouza = (event) => {
        const value = event.target.value;
        setSelectedMouza(value);
        myContext.setMouzaName(value);
        fetchPlotsData(selectedThana, value);
    };

    const fetchPlotsData = (thana, mouza) => {
        fetch(`http://114.130.119.173:4040/api/select/plot?thana=${thana}&mouza=${mouza}`)
            .then(response => response.json())
            .then(data => {
                if (data[0].plots) {
                    setPlots(data[0].plots);
                    myContext.setExtent(FormatExtent(data[1].bbox));
                    setSelectedPlot([]);
                    setPlotInfo([]);
                } else {
                    setPlots(null);
                }
            });
    };

    const handleSelectPlot = (event) => {
        const value = event;
        setSelectedPlot(value);
        setIsDropdownOpen(false);

        fetch(`http://114.130.119.173:4040/api/select/plotinfo?thana=${selectedThana}&mouza=${selectedMouza}&plot=${value}`)
            .then(response => response.json())
            .then(data => {
                setPlotInfo(data[0]);
                myContext.setPlotCenter(FormatExtent(data[1].center));
                myContext.setPlotNo(value);
            });
    };

    const translateCategories = (inputString) => {
        const categories = inputString.split(', ');
        const translatedCategories = categories.map(category => categoryMapping[category] || category);
        return translatedCategories.join(', ');
    };

    const handlePlotSearch = (event) => {
        const { value } = event.target;
        setPlotSearchQuery(value);
        const filteredPlots = plots.filter(plot => plot.toString().includes(value)).slice(0, 10);
        setSuggestions(filteredPlots);
        setIsDropdownOpen(value.length > 0);
    };

    const handleSelectSuggestion = (suggestion) => {
        setPlotSearchQuery(suggestion);
        handleSelectPlot(suggestion);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSelectPlot(plotSearchQuery);
            setIsDropdownOpen(false);
        }
    };

    const categoryMapping = {
        'Restricted Area': 'সংরক্ষিত এলাকা',
        'Recreation and Open Space': 'বিনোদন ও খেলার জায়গা',
        'Industry': 'শিল্প',
        'Education and Research': 'শিক্ষা',
        'Commercial': 'বাণিজ্যিক',
        'Transport and Communication': 'সড়ক ও যোগাযোগ',
        'Residential': 'আবাসিক',
        'Agriculture': 'কৃষি',
        'Waterbody': 'জলাশয়',
        'Mixed Use': 'মিশ্র',
        'Service Activity': 'সেবা',
        'Government Service': 'সরকারি জমি'
        // Add mappings for other categories here
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <button type="button" className={`btn toggle-button pt-10 sidebarBtn`} onClick={toggleSidebar} style={{ left: isOpen ? '300px' : '5px', transition: 'left 0.3s ease-in-out' }} >
                {/* {isOpen ? 'বন্ধ করুন  ' : 'আপনার ভুমির ব্যবহার   '}  */}
                <i className={`fa fa-${isOpen ? 'times' : 'search'}`}></i>
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="mb-3">
                    <p className="form-label"><b>আপনার ভুমির ব্যবহার দেখুন</b></p>
                    <label className="form-label">উপজেলা / থানা নির্বাচন করুন:</label>
                    <select className="form-select" value={selectedThana} onChange={handleSelectThana} >
                        <option value="">--তালিকা--</option>
                        {thanas && thanas.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                {mouzas.length > 0 && (
                    <div className="mb-3">
                        <label className="form-label">মৌজা নির্বাচন করুন:</label>
                        <select className="form-select" value={selectedMouza} onChange={handleSelectMouza}>
                            <option value="">--তালিকা--</option>
                            {mouzas.map((mouza) => (
                                <option key={mouza} value={mouza}>
                                    {mouza}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                {plots.length > 0 && (
                    <div className="mb-3">
                        <label className="form-label">দাগ নাম্বার নির্বাচন করুন:</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="দাগ নাম্বার লিখুন"
                                value={plotSearchQuery}
                                onChange={handlePlotSearch}
                                onKeyDown={handleKeyDown}
                            />
                            {isDropdownOpen && suggestions.length > 0 && (
                                <div className="suggestions" style={{ paddingLeft: '5px' }}>
                                    {suggestions.map((suggestion, index) => (
                                        <div
                                            key={index}
                                            className="suggestion-item"
                                            onClick={() => handleSelectSuggestion(suggestion)}
                                        >
                                            {suggestion}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {plotInfo.length > 0 && (
                    <div className='plot_result'>
                        <h5 className='text-center'>ফলাফল</h5>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <td><b>থানা: </b></td>
                                        <td>{plotInfo[0].m_thana}</td>

                                    </tr>
                                    <tr>
                                        <td><b>মৌজা: </b></td>
                                        <td>{plotInfo[0].mouza}</td>

                                    </tr>
                                    <tr>
                                        <td><b>শিট নং</b></td>
                                        <td>{plotInfo[0].sheet_no}</td>

                                    </tr>
                                    <tr>
                                        <td><b>জে এল নং</b></td>
                                        <td>{plotInfo[0].jl_no}</td>

                                    </tr>
                                    <tr>
                                        <td><b>দাগের ধরন</b></td>
                                        <td>{plotInfo[0].mz_ver}</td>

                                    </tr>
                                    <tr>
                                        <td><b>দাগ নং</b></td>
                                        <td>{plotInfo[0].plot_no}</td>

                                    </tr>

                                    <tr>
                                        <td><b>ভূমি ব্যাবহার</b></td>
                                        <td ><p style={{ color: 'green' }}><b>{plotInfo[0].landuse ? translateCategories(plotInfo[0].landuse) : 'উল্লেখ নাই'}</b></p></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center">
                            <button className="prntbtn btn btn-primary btn-block mb-2 text-center" onClick={() => toPDF()}>প্রিন্ট করুন</button>
                        </div>
                    </div>
                )

                }

            </div >
        </>
    );
};

export default Sidebar;
