import React from 'react';
import './ColorBlockWithText.css'; // Create a CSS file for styling

function ColorBlockWithText({ color, text, borderColor, borderSize }) {
  const blockStyle = {
    backgroundColor: color,
    border: `${borderSize}px solid ${borderColor}`
  };

  return (
    <div className="color-block-with-text ">
      <div className="color-block" style={blockStyle}></div>
      <div className="text">{text}</div>
    </div>
  );
}

export default ColorBlockWithText;
