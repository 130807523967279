import { useEffect, useState } from 'react';
import './footer.css';

const Footer = () => {
    const [visitorCount, setVisitorCount] = useState(0);

    useEffect(() => {
        fetchTVisiorData();
    }, [])
    const fetchTVisiorData = () => {
        fetch('http://114.130.119.173:4040/visitor-count')
            .then(response => response.json())
            .then(data => {
                setVisitorCount(data?.count)
            })
    };
    return (
        <>

            <footer className="text-white py-2 absulate bottom-0 w-100 footer">
                <div className="container">
                    <div className="row align-items-center ">
                    <div className="col-sm-3 text-sm-left text-left footer_left absolute">
                           <p> Visitor Count: {visitorCount}</p>
                        </div>
                        <div className="col-12 text-center copyright">
                            <p className="mb-0">Copyright &copy; 2023 Khulna Development Authority</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
